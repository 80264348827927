<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from '../store'

export default {
  name: 'AnalysisTypeMixin',
  created () {
    if (!this.analysisTypeChoicesList || (this.analysisTypeChoicesList && this.analysisTypeChoicesList.length === 0)) {
      this.getAnalysisTypeChoicesList()
    }
  },
  computed: {
    ...mapGetters({
      analysisTypeChoicesList: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.GETTERS.analysisTypeChoicesList
    }),
    analysisTypeListOptions () {
      const options = []
      if (this.analysisTypeChoicesList && this.analysisTypeChoicesList.length > 0) {
        this.analysisTypeChoicesList.forEach((instance) => {
          options.push({
            value: instance[0],
            text: instance[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getAnalysisTypeChoicesList:
        PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.ACTIONS.getAnalysistypechoiceslist
    }),
    selectAnalysisType (item) {
      this.$set(this.form, 'analysis_type', item.value)
      this.$set(this.form, 'analysis_type_name', item.text)
    },
    clearAnalysisType () {
      this.$set(this.form, 'analysis_type', null)
      this.$set(this.form, 'analysis_type_name', null)
    }
  }
}
</script>
