<template>
  <b-modal
      :no-enforce-focus = 'true'
      ref="modalSelectAddress"
      id="modalSelectAddress"
      size='lg'
      @hidden="modalSelectAddressOnHidden"
      class="modal-top">
      <div slot="modal-title">
        {{ 'Select address' |translate}}
      </div>

      <thux-table
        :instance="instance"
        :instance-name="instanceName"
        :order-by-dict="orderByDict"
        :goBack="goBack"
        :list="organizationAddressList"
        :local-selected-list="[]"
        :selected-list="[]"
        :fields-column="[]"
        :fieldName="fieldName"
        :fields="fields"
        :show-checks="false"
        :showDetailButton="false"
      >
        <template slot="other-buttons-actions-bottom" slot-scope="row">
          <b-btn
            variant="outline-primary"
            :title="'Select' | translate"
            @click.prevent="$emit('close-select-address', row.item)"
          >
            <i class="fas fa-check"></i>
          </b-btn>
        </template>
      </thux-table>
       <div slot="modal-footer" class="w-100">
       </div>
    </b-modal>
</template>

<script>
import OwnerAddressMixin from '../proposal/mixins/OwnerAddressMixin'

import ThuxTableMixin from '@/components/thux-table/ThuxTableMixin'

export default {
  name: 'SelectAddressModal',
  mixins: [OwnerAddressMixin, ThuxTableMixin],
  props: ['orgId'],
  data () {
    return {
      fields: [
        {
          key: 'name',
          sortable: false,
          label: this.$t('Name'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'full_address'
        },
        {
          key: 'scopes',
          sortable: false,
          label: this.$t('Typology'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          value: 'scopes'
        },
        {
          key: 'action',
          sortable: false,
          label: '',
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ]
    }
  },
  mounted () {
    this.openModalSelectAddress()
  },
  methods: {
    openModalSelectAddress () {
      this.$refs.modalSelectAddress.show()
    },
    modalSelectAddressOnHidden (evt) {
      this.$refs.modalSelectAddress.hide()
      this.$emit('close-select-address')
    }
  },
  created () {
    this.setOrganizationAddressList([])
    if (this.orgId) {
      const filters = { no_page: 'no_page', filter__organization__id: this.orgId, filter__status: 1 }
      this.getOrganizationAddressList(filters)
    }
  }
}
</script>
