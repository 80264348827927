<template>
  <b-form>
    <b-form-row>
      <form-thux-horizontal-multiselect
        v-if="showAddressScope"
        :validator="$v.form.address_scopes"
        label-form="Address scope"
        label-form-class="mb-2"
        class-form="col-lg-6 mb-2 mt-2"
        :external-errors="errors['address_scopes']"
        label-search="name"
        labelColsSm="3"
        labelColsLg="3"
        :disabled="!formEditableUpdate"
        :value="form.address_scopes"
        :options="addressScopeListOptions"
        :placeholder="
          $t('Search or select a {instance}', { instance: $t('address scope') })
        "
        @remove="removeAddressScope"
        @select="selectAddressScope"
        @clear="$set(form, 'address_scopes', null)"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-autocomplete
        :validator="$v.form.address_type"
        :external-errors="errors['address_type']"
        :label-form="'Address Type' | translate"
        class-form="col-6 mb-2 mt-2"
        :disabled="!formEditableUpdate"
        :label-search="'name'"
        labelColsSm="4"
        labelColsLg="3"
        :value="form.address_type ? { value: form.address_type, name: form.address_type_name } : null"
        :options="addressTypeListOptions"
        :placeholder="$t('Select a address type')"
        :placeholder-field="form.address_type_name"
        @select="selectAddressType"
        @clear="clearAddressType"
      />
      <form-thux-horizontal-input
        :validator="$v.form.name"
        :external-errors="errors['name']"
        :label-form="'Address' | translate"
        class-form="col-lg-6 mb-2 mt-2"
        type="text"
        labelColsSm="3"
        labelColsLg="3"
        :value="form.name"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'name', $event)"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-input
        :validator="$v.form.number"
        :external-errors="errors['number']"
        :label-form="'Number' | translate"
        class-form="col-lg-6 mb-2 mt-2"
        type="text"
        labelColsSm="3"
        labelColsLg="3"
        :value="form.number"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'number', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.zip_code"
        :external-errors="errors['zip_code']"
        :label-form="'CAP' | translate"
        class-form="col-lg-6 mb-2 mt-2"
        type="text"
        labelColsSm="3"
        labelColsLg="3"
        :value="form.zip_code"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'zip_code', $event)"
      />
    </b-form-row>
    <b-form-row>
      <form-thux-horizontal-input
        :validator="$v.form.locality"
        :external-errors="errors['locality']"
        :label-form="'Locality' | translate"
        class-form="col-lg-6 mb-2 mt-2"
        type="text"
        labelColsSm="3"
        labelColsLg="3"
        :value="form.locality"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'locality', $event)"
      />
      <form-thux-horizontal-input
        :validator="$v.form.province"
        :external-errors="errors['province']"
        :label-form="'Province (abbr.)' | translate"
        class-form="col-lg-6 mb-2 mt-2"
        type="text"
        labelColsSm="3"
        labelColsLg="3"
        maxlength="2"
        :value="form.province"
        :disabled="!formEditableUpdate"
        @change="$set(form, 'province', $event ? $event.toUpperCase() : null)"
      />
      <form-thux-horizontal-autocomplete
        :validator="$v.form.country"
        :external-errors="errors['country']"
        :label-form="'Country' | translate"
        class-form="col-lg-6 mb-2 mt-2"
        labelColsSm="3"
        labelColsLg="3"
        :disabled="!formEditableUpdate"
        :label-search="'name'"
        :value="form.country ? { value: form.country, name: form.country_name } : null"
        :options="countryListOptions"
        :placeholder="$t('Type a country')"
        :placeholder-field="form.country_name"
        @select="selectCountry"
        @clear="clearCountry"
      />
    </b-form-row>
  </b-form>
</template>

<script>
import { maxLength, required } from 'vuelidate/lib/validators'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import AddressScopeMixin from '../../organization/organization/organization-address/mixins/AddressScopeMixin'
import CountryMixin from '../../organization/organization/organization-address/mixins/CountryMixin'
import { cloneDeep } from 'lodash'
import AddressTypeMixinVue from '../../organization/organization/organization-address/mixins/AddressTypeMixin'

export default {
  name: 'AddressComponent',
  props: {
    detail: {},
    requiredEdit: { default: true },
    formEditableUpdate: {},
    otherCondition: {},
    resetAddress: {},
    errors: {},
    showAddressScope: {},
    selectedAddress: {}
  },
  mixins: [ThuxDetailPanelMixin, AddressScopeMixin, CountryMixin, AddressTypeMixinVue],
  watch: {
    detail: {
      handler () {
        if (this.detail) {
          this.$set(this, 'form', this.setForm(cloneDeep(this.detail)))
        }
      },
      deep: true
    },
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    selectedAddress () {
      if (this.selectedAddress) {
        this.$set(this, 'form', {
          id: this.form.id,
          address_type: this.selectedAddress.address_type,
          address_type_name: this.selectedAddress.address_type_name,
          name: this.selectedAddress.name,
          number: this.selectedAddress.number,
          zip_code: this.selectedAddress.zip_code,
          locality: this.selectedAddress.locality,
          province: this.selectedAddress.province,
          country: this.selectedAddress.country,
          country_name: this.selectedAddress.country_name,
          address_scopes: this.selectedAddress.address_scopes
        })
        this.$set(this.form, 'address_scopes', this.getAddressScopes(this.form))
      }
    },
    resetAddress () {
      this.$set(this, 'form', cloneDeep(this.detail))
      this.$emit('set-form', this.form)
      this.$emit('reset')
    }
  },
  computed: {
    checkIfFormIsEmpty () {
      if (this.form.address_scopes && this.form.address_scopes.length > 0) return false
      if (this.otherCondition) return false
      if (this.form.name && this.form.name !== '') return false
      if (this.form.number && this.form.number !== '') return false
      if (this.form.zip_code && this.form.zip_code !== '') return false
      if (this.form.locality && this.form.locality !== '') return false
      if (this.form.province && this.form.province !== '') return false
      if (this.form.country && this.form.country !== '') return false
      return true
    }
  },
  methods: {
    init () {
      if (this.id) {
        this.makeFormReadonly()
        this.setForm(this.detail)
      } else {
        this.makeFormEditable()
      }
    },
    getAddressValidator (validator) {
      if (!this.checkIfFormIsEmpty || this.form.id) {
        validator.name = { required }
        validator.country = { required }
      } else {
        validator.name = {}
        validator.country = {}
      }
      return validator
    },
    setForm (detail) {
      const form = {
        id: detail.id,
        address_type: detail.address_type,
        address_type_name: detail.address_type_name,
        name: detail.name,
        number: detail.number,
        zip_code: detail.zip_code,
        locality: detail.locality,
        province: detail.province,
        country: detail.country,
        country_name: detail.country_name,
        address_scopes: detail.address_scopes
      }
      this.$set(form, 'address_scopes', this.getAddressScopes(form))
      return form
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      this.setAddressScopes(formData)
      return formData
    }
  },
  validations () {
    const validator = {
      form: {
        address_scopes: {},
        address_type: {},
        name: {},
        number: {
          maxLength: maxLength(20)
        },
        zip_code: {
          maxLength: maxLength(20)
        },
        locality: {},
        province: {
          maxLength: maxLength(2)
        },
        country: {}
      }
    }
    validator.form = this.getAddressValidator(validator.form)
    return validator
  }
}
</script>
