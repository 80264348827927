<template>
  <div class="mb-3" style="border: 1px solid; padding: 15px;">
    <p class="font-weight-bold">{{ 'Select Pidueci recipient emails' | translate }}:</p>
    <b-form>
      <b-form-row>
        <form-thux-horizontal-checkbox
          :validator="$v.email"
          v-for="email in primaryEmailsList" :key='email.id'
          :label-form="email.email"
          class-form="col-lg-6 mb-2"
          label-cols-lg="7"
          label-cols-sm="7"
          :value="emailList[email.email]"
          @change="setSelectedEmail($event, email.email)"
        />
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as OWNER_CONTACT_EMAIL_TYPES } from '../pages/organization/owner-contact-emails/store'
import FormThuxHorizontalCheckbox from '@/components/form-thux-horizontal-components/FormThuxHorizontalCheckbox'

export default {
  name: 'OwnerContactEmailsCheckbox',
  props: ['selectedEmails'],
  components: {
    FormThuxHorizontalCheckbox
  },
  watch: {
    selectedEmails () {
      this.primaryEmailsList.forEach(el => {
        console.log(this.selectedEmails)
        if (this.selectedEmails.includes(el.email)) {
          this.$set(this.emailList, el.email, true)
        } else {
          this.$set(this.emailList, el.email, false)
        }
      })
    }
  },
  data () {
    return {
      emailList: {}
    }
  },
  created () {
    this.setPrimaryEmailsFilters({ }).then(() => {
      this.primaryEmailsList.forEach(el => {
        this.emailList[el.email] = false
      })
    })
  },
  computed: {
    ...mapGetters({
      primaryEmailsList: OWNER_CONTACT_EMAIL_TYPES.GENERIC.organization.ownercontactemail.LIST.GETTERS.primaryEmailsList
    })
  },
  methods: {
    ...mapActions({
      setPrimaryEmailsFilters: OWNER_CONTACT_EMAIL_TYPES.GENERIC.organization.ownercontactemail.LIST.ACTIONS.setPrimaryEmailsFilters
    }),
    setSelectedEmail (evt, email) {
      if (evt) {
        if (!this.selectedEmails.includes(email)) {
          this.$emit('add-email', email)
        }
      }
      if (evt === false) {
        if (this.selectedEmails.includes(email)) {
          this.$emit('remove-email', email)
        }
      }
      this.$set(this.emailList, email, evt)
    }
  },
  validations: {
    email: {}
  }
}
</script>
