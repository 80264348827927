<template>
  <div class="sidebar-filters">
    <b-button class="text-uppercase" ref="escape" variant="reset-all" @click.prevent="resetFilters()">
      <i class="fas fa-eraser" /> {{ 'Reset all filters' | translate }}
    </b-button>
    <status-list-filter
      @edit="editComponentFilters($event, 'filter__status__in')"
      @clean="cleanComponentFilters(['filter__status__in'])"
    />
    <organization-list-filter
      @edit="editComponentFilters($event, 'filter__owner__in')"
      @clean="cleanComponentFilters(['filter__owner__in'])"
    />
    <contact-list-filter
      @edit="editComponentFilters($event, 'filter__owner_contact__in')"
      @clean="cleanComponentFilters(['filter__owner_contact__in'])"
    />
    <analysis-type-choices-list-filter
      @edit="editComponentFilters($event, 'filter__analysis_type')"
      @clean="cleanComponentFilters(['filter__analysis_type'])"
    />
    <open-close-proposal-list-filter
      @edit="editComponentFilters($event, 'filter__is_closed')"
      @clean="cleanComponentFilters(['filter__is_closed'])"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from './store'
import CommonFiltersMixin from '../../common-filters/CommonFiltersMixin'

export default {
  name: 'ProposalCommonFilters',
  mixins: [CommonFiltersMixin],
  computed: {
    ...mapGetters({
      filters: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.GETTERS.filters
    })
  },
  methods: {
    ...mapActions({
      ...PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.ACTIONS
    })
  }
}
</script>
