<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/organization/store'
import { vue } from '../../../../main'

export default {
  name: 'OwnerMixin',
  created () {
    this.setOrganizationList({})
  },
  computed: {
    ...mapGetters({
      organizationList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.GETTERS.list
    }),
    ownersOptions () {
      const options = []
      if (this.organizationList.results && Array.isArray(this.organizationList.results)) {
        this.organizationList.results.forEach((instance) => {
          options.push({
            value: instance.id,
            name: instance.typology_name ? `${instance.name} (${instance.typology_name})` : instance.name
          })
        })
      }
      return options
    },
    clientOptions () {
      const options = []
      if (this.organizationList.results && Array.isArray(this.organizationList.results)) {
        this.organizationList.results.forEach((instance) => {
          options.push({
            value: instance.id,
            name: instance.typology_name ? `${instance.name} (${instance.typology_name})` : instance.name
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setOrganizationList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getOrganizationList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters
    }),
    onSearchOwner (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status.-date_create'
        this.getOrganizationList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setOrganizationList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    onSearchYardClient (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status.-date_create'
        this.getOrganizationList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setOrganizationList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectOwner (item) {
      const result = this.organizationList.results.find(obj => {
        return obj.id === item.value
      })
      if (result) {
        this.$set(this.form, 'owner_vat_number', result.vat_number)
        this.$set(this.form, 'owner_ssn', result.ssn)
        this.$set(this.form, 'owner_phone_number', result.default_phone)
        this.$set(this.form, 'owner_email', result.default_email)
      }
      this.$set(this.form, 'owner', item.value)
      this.$set(this.form, 'owner_name', item.name)
      this.$set(this.form, 'owner_contact', null)
      this.$set(this.form, 'owner_contact_full_name', null)
    },
    clearOwner () {
      this.$set(this.form, 'owner', null)
      this.$set(this.form, 'owner_name', null)
      this.$set(this.form, 'owner_contact', null)
      this.$set(this.form, 'owner_phone_number', null)
      this.$set(this.form, 'owner_email', null)
      this.$set(this.form, 'owner_contact_full_name', null)
      this.$set(this.form, 'owner_vat_number', null)
      this.$set(this.form, 'owner_ssn', null)
    },
    selectYardClient (item) {
      this.$set(this.form, 'client', item.value)
      this.$set(this.form, 'client_name', item.name)
    },
    clearYardClient () {
      this.$set(this.form, 'client', null)
      this.$set(this.form, 'client_name', null)
    }
  }
}
</script>
