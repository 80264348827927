<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CONTACT_TYPES } from '../../../organization/contact/store'
import { vue } from '../../../../main'

export default {
  name: 'OwnerContactMixin',
  created () {
    this.setContactList({})
  },
  computed: {
    ...mapGetters({
      contactList: CONTACT_TYPES.GENERIC.organization.contact.LIST.GETTERS.list
    }),
    ownerContactsOptions () {
      const options = []
      if (this.contactList && this.contactList.results && this.contactList.results.length > 0) {
        this.contactList.results.forEach((instance) => {
          options.push({
            id: instance.id,
            name: `${instance.last_name} ${instance.first_name}`
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setContactList: CONTACT_TYPES.GENERIC.organization.contact.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getContactList: CONTACT_TYPES.GENERIC.organization.contact.LIST.ACTIONS.setFilters
    }),
    onSearchOwnerContact (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        if (this.form.owner) {
          filters.filter__organizations__id = this.form.owner
        }
        filters.filter__last_name__icontains = search
        filters.order_by = '-status.-date_create'
        this.getContactList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setContactList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectOwnerContact (item) {
      this.$set(this.form, 'owner_contact', item.id)
      this.$set(this.form, 'owner_contact_full_name', item.name)
    },
    clearOwnerContact () {
      this.$set(this.form, 'owner_contact', null)
      this.$set(this.form, 'owner_contact_full_name', null)
    }
  }
}
</script>
