<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '@/main'
import { TYPES as OWNER_TYPES } from '@/pages/organization/contact/contact/owner-contact/store'

export default {
  name: 'ReferentMixin',
  created () {
    if (!this.referentList || (this.referentList && this.referentList.length === 0)) {
      this.setReferentList()
    }
  },
  data () {
    return {
      referents: []
    }
  },
  computed: {
    ...mapGetters({
      referentList: OWNER_TYPES.GENERIC.organization.ownercontact.LIST.GETTERS.list
    }),
    referentListOptions () {
      const options = []
      if (this.referentList && this.referentList.results && this.referentList.results.length > 0) {
        this.referentList.results.forEach((referent) => {
          const option = {
            status: referent.status,
            id: referent.id,
            name: `${referent.first_name} ${referent.last_name}`,
            phone: referent.primary_phone,
            email: referent.primary_email
          }
          if (option.status === 0) {
            option.$isDisabled = true
          }
          if (this.referents && this.referents.length > 0) {
            if (!this.referents.some((instance) => { return option.id === instance.id })) {
              options.push(option)
            }
          } else {
            options.push(option)
          }
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setReferentList: OWNER_TYPES.GENERIC.organization.ownercontact.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getReferentList: OWNER_TYPES.GENERIC.organization.ownercontact.LIST.ACTIONS.setFilters
    }),
    onSearchReferent (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getReferentList({ filter__last_name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setReferentList({})
      }
    },
    selectReferent (item, index) {
      const instance = {
        id: item.id,
        name: item.name,
        phone: item.phone,
        email: item.email
      }
      this.$set(this.referents, index, instance)
      this.$emit('update-referents', this.referents.map((referent) => referent.id))
      this.referents.push({})
    },
    clearReferent (index) {
      this.referents.splice(index, 1)
      this.$emit('update-referents', this.referents.map((referent) => referent.id))
      if (this.referents.length === 0) {
        this.$emit('update-referents', null)
        this.referents = [{}]
      }
    }
  }
}
</script>
