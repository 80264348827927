<template>
  <b-modal
    id="modalSendEmails"
    ref="modalSendEmails"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
    size="lg"
    @hidden="modalSendEmailsOnHidden"
  >
    <div slot="modal-title">
      {{ title }}
    </div>
    <owner-contact-emails-checkbox
      v-if="showOwnerEmails"
      :selectedEmails='form.emails ? form.emails : []'
      @add-email="addOwnerEmailToList"
      @remove-email="removeOwnerEmailToList"
    ></owner-contact-emails-checkbox>
    <b-form @submit.prevent>
      <b-form-row>
        <form-group-thux
          :validator="$v.form.emails"
          :label="'Insert emails' | translate"
          label-class="mb-2 font-weight-bold"
          class="col-12 mb-3"
        >
          <div class="input-group mb-2">
            <span
              class="input-group-prepend"
              :class="!areEmailsValid ? 'input-border-red' : ''"
            >
              <span class="input-group-text">
                <i class="fas fa-envelope"></i>
              </span>
            </span>
            <b-form-tags
              id="input-email"
              tag-variant="tag"
              add-button-variant="outline-primary"
              :tag-validator="isEmailValid"
              :state="areEmailsValid"
              :placeholder="$t('Insert an email address')"
              :invalid-tag-text="$t('Email not valid')"
              :duplicate-tag-text="$t('Duplicate email')"
              v-model="form.emails"
              remove-on-delete
            >
              <template slot="add-button-text">
                <i class="fas fa-plus mr-1"></i>
                {{ 'Add' | translate }}
              </template>
            </b-form-tags>
          </div>
        </form-group-thux>
      </b-form-row>
    </b-form>
    <slot name="body">
      <span style="font-size: 1.1rem">{{ getBody }}</span>
    </slot>
    <div slot="modal-footer">
      <!--BUTTON CANCEL-->
      <b-btn class="mr-2" variant="default" @click="modalSendEmailsOnHidden">
        {{ 'Cancel' | translate }}
      </b-btn>
      <!--BUTTON SEND-->
      <b-btn variant="primary" :disabled="$v.form.$invalid" @click="onSubmit">
        {{ 'Send' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import OwnerContactEmailsCheckbox from '../components/OwnerContactEmailsCheckbox.vue'

export default {
  name: 'SendEmailModal',
  props: {
    title: { required: true },
    defaultEmail: {},
    body: {},
    showOwnerEmails: { required: true }
  },
  components: {
    OwnerContactEmailsCheckbox
  },
  data () {
    return {
      email: '',
      form: {
        emails: []
      },
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    }
  },
  mounted () {
    if (this.defaultEmail) {
      this.defaultEmail.split(';').forEach(email => {
        this.form.emails.push(email)
      })
    }
    this.openModalSendEmails()
  },
  computed: {
    areEmailsValid () {
      if (this.form.emails && this.form.emails.length > 0 && this.isEmailValid(this.email)) return true
      return !!(this.form.emails && this.form.emails.length === 0 && this.isEmailValid(this.email))
    },
    getBody () {
      return this.$t(this.body ? this.body : '')
    }
  },
  methods: {
    isEmailValid (email) {
      this.$set(this, 'email', email)
      if (email === '' || !email) return true
      return this.reg.test(email)
    },

    // MODAL
    openModalSendEmails () {
      this.$refs.modalSendEmails.show()
    },
    modalSendEmailsOnHidden () {
      this.$emit('close')
      this.$refs.modalSendEmails.hide()
    },
    onSubmit () {
      if (!this.$v.form.$invalid) {
        this.$emit('submit', this.form.emails)
      }
    },
    addOwnerEmailToList (email) {
      let emailList = Object.assign([], this.form.emails)
      if (emailList === undefined) {
        emailList = []
      }
      emailList.push(email)
      this.$set(this.form, 'emails', emailList)
    },
    removeOwnerEmailToList (email) {
      const emailList = Object.assign([], this.form.emails)
      const index = emailList.indexOf(email)
      if (index > -1) {
        emailList.splice(index, 1)
      }
      this.$set(this.form, 'emails', emailList)
    }
  },
  validations: {
    form: {
      emails: {
        required,
        minLength: minLength(1)
      }
    }
  }
}
</script>
