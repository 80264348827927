<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_ADDRESS_TYPES } from '../store'
import PopulateSelectMixin from '@/components/PopulateSelectMixin'

export default {
  name: 'AddressTypeMixin',
  mixins: [PopulateSelectMixin],
  created () {
    if (!this.addressTypeList || (this.addressTypeList && this.addressTypeList.length === 0)) {
      this.getAddressTypeList()
    }
  },
  computed: {
    ...mapGetters({
      addressTypeList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.GETTERS.typologyChoices
    }),
    addressTypeListOptions () {
      return this.prepareChoiceListForSelect(this.addressTypeList, 0, 1)
    }
  },
  methods: {
    ...mapActions({
      getAddressTypeList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.ACTIONS.getTypologychoices
    }),
    selectAddressType (item) {
      this.$set(this.form, 'address_type', item.value)
      this.$set(this.form, 'address_type_name', item.name)
    },
    clearAddressType (item) {
      this.$set(this.form, 'address_type', null)
      this.$set(this.form, 'address_type_name', null)
    }
  }
}
</script>
