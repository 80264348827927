<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORGANIZATION_TYPES } from '../../../organization/organization/store'
import { vue } from '../../../../main'

export default {
  name: 'ClientsMixin',
  created () {
    this.setList({})
  },
  computed: {
    ...mapGetters({
      clientsList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.GETTERS.list
    }),
    clientsListOptions () {
      const options = []
      if (this.clientsList && this.clientsList.results && this.clientsList.results.length > 0) {
        this.clientsList.results.forEach((client) => {
          const option = {
            id: client.id,
            name: client.typology_name ? `${client.name} (${client.typology_name})` : client.name
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getClientsList: ORGANIZATION_TYPES.GENERIC.organization.organization.LIST.ACTIONS.setFilters
    }),
    onSearchClients (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        this.getClientsList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectClients (item) {
      if (!this.form.clients) {
        this.$set(this.form, 'clients', [item])
      } else {
        this.form.clients.push(item)
      }
    },
    removeClients (item) {
      let clients = this.form.clients.filter((client) => {
        return client.id !== item.id
      })
      if (clients.length === 0) clients = null
      this.$set(this.form, 'clients', clients)
    },
    getClients (formData) {
      const values = []
      if (formData.clients) {
        this.clientsListOptions.forEach(element => {
          if (formData.clients.includes(element.id)) {
            values.push({
              id: element.id,
              name: element.typology_name ? `${element.name} (${element.typology_name})` : element.name
            })
          }
        })
        return values
      }
    },
    setClients (formData) {
      if (formData && formData.clients) {
        const list = []
        formData.clients.forEach((row) => {
          if (row instanceof Object) {
            list.push(row.id)
          } else {
            list.push(row)
          }
        })
        this.$set(formData, 'clients', new Set(list))
      } else {
        this.$set(formData, 'clients', [])
      }
    }
  }
}
</script>
