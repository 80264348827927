<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_ADDRESS_TYPES } from '../store'

export default {
  name: 'AddressScopeMixin',
  created () {
    if (!this.addressScopeList || (this.addressScopeList && this.addressScopeList.length === 0)) {
      this.getAddressscopeList()
    }
  },
  computed: {
    ...mapGetters({
      addressScopeList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.GETTERS.addressScopes
    }),
    addressScopeListOptions () {
      const options = []
      if (this.addressScopeList && this.addressScopeList.length > 0) {
        this.addressScopeList.forEach((instance) => {
          options.push({
            id: instance.id,
            name: instance.name
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getAddressscopeList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.ACTIONS.getAddressscopes
    }),
    getAddressScopes (form) {
      const values = []
      if (form.address_scopes) {
        this.addressScopeList.forEach(element => {
          if (form.address_scopes.includes(element.id)) {
            values.push({
              id: element.id,
              name: `${element.name}`,
              status: element.status
            })
          }
        })
        return values
      }
    },
    setAddressScopes (formData) {
      if (this.form.address_scopes) {
        const list = []
        this.form.address_scopes.forEach((row) => list.push(row.id))
        this.$set(formData, 'address_scopes', new Set(list))
      } else {
        this.$set(formData, 'address_scopes', [])
      }
    },
    selectAddressScope (item) {
      if (!this.form.address_scopes) {
        this.$set(this.form, 'address_scopes', [])
      }
      this.form.address_scopes.push(item)
    },
    removeAddressScope (item) {
      const addressScopes = this.form.address_scopes.filter((addressScope) => {
        return addressScope.id !== item.id
      })
      this.$set(this.form, 'address_scopes', addressScopes)
      if (this.form.address_scopes.length === 0) this.$set(this.form, 'address_scopes', null)
    }
  }
}
</script>
