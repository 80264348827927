<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll('id')"
      @check-none="checkNone('id')"
      @set-selected-row="setSelectedRow($event, 'id')"
      :show-checks="showChecks"
      :showDetailButton="showDetailButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="is_closed" slot-scope="row">
        <div v-if="row.item.is_closed">
           <i class="fas fa-lock text-red" :title="$t('Closed')"></i>
         </div>
        <div v-else>
          <i class="fas fa-lock-open text-green" :title="$t('Opened')"></i>
        </div>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="downloadPDFRolePerm"
          :title="'Download PDF' | translate"
          @click.prevent="downloadPDF(row.item)"
        >
          <i class="fas fa-file-download"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="sendEmailRolePerm"
          :title="'Send email for proposal' | translate"
          :disabled="row.item.is_closed"
          @click.prevent="showModal(row.item, 'showSendEmailModal')"
        >
          <i class="fas fa-envelope"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="uploadSignedRolePerm"
          :title="'Upload signed' | translate"
          @click.prevent="showModal(row.item, 'showUploadSignedModal')"
        >
          <i class="fas fa-upload"></i>
        </b-btn>
        <b-btn
          :disabled="!row.item.signed_proposal"
          variant="outline-primary"
          v-has-perms="downloadSignedRolePerm"
          :title="'Download signed' | translate"
          @click.prevent="downloadProposalSigned(row.item)"
        >
          <i class="fas fa-download"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="createQuotationRolePerm"
          :title="'Create quotation' | translate"
          :disabled="!row.item.is_closed"
          @click.prevent="createQuotationFromProposal(row.item)"
        >
          <i class="fas fa-calculator"></i>
        </b-btn>
        <b-btn
          variant="outline-primary"
          v-has-perms="deleteRolePerm"
          :title="'Delete' | translate"
          @click="showConfirmModal(row.item)"
        >
          <i class="far fa-trash-alt"></i>
        </b-btn>
      </template>
    </thux-table>
    <send-email-modal
      v-if="showSendEmailModal && proposal && proposal.id"
      :show-owner-emails='true'
      :title="$t(
          'Send proposal for {work_subject}) by email',
          { work_subject: $options.filters.truncate(proposal.work_subject, 60) }
          )"
      :default-email="proposal.owner_email"
      @submit="sendProposalEmail"
      @close="closeModal"
    />
    <file-upload-modal
      v-if="showUploadSignedModal && proposal && proposal.id"
      :title="$t('Upload signed')"
      @submit="uploadProposalSigned"
      @close="closeModal"
    />
    <update-owner-data-modal
      v-if="showUpdateOwnerDataModal && proposal && proposal.id"
      :title="$t('Update owner ssn or vat number')"
      :owner-name="proposal.owner_name"
      :ownerId="proposal.owner"
      @submit-and-update="updateProposalData"
      @close="closeModal"
    />
    <ConfirmDialog
      v-if="showDeleteModal && proposal.id"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this proposal?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeModal"
      @confirm-dialog-select="deleteProposal({ id: proposal.id })"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from './store'
import { ROUTES as PROPOSAL_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import SendEmailModal from '../../../components/SendEmailModal'

import JobMixin from '../../../components/job-button/JobMixin'
import FileUploadModal from '../../../components/FileUploadModal'
import UpdateOwnerDataModal from '../components/UpdateOwnerDataModal'
import { storeMixin } from '../../../storeMixin'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: 'ProposalTable',
  components: { SendEmailModal, FileUploadModal, UpdateOwnerDataModal, ConfirmDialog },
  mixins: [ThuxTableMixin, ThuxTableChecksMixin, JobMixin],
  data () {
    return {
      apiCallJobUrl: `${this.apiUrl}/send-emails-proposal-job/`,
      showSendEmailModal: false,
      showUploadSignedModal: false,
      showDeleteModal: false,
      proposal: null,
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: true,
          value: 'status'
        },
        {
          key: 'is_closed',
          label: this.$t('Processing state'),
          type: 'boolean',
          tdClass: 'text-center',
          thClass: 'text-center text-wrap',
          sortable: true,
          value: 'is_closed',
          custom: true
        },
        {
          key: 'date_p',
          label: this.$t('Date'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          type: 'date',
          value: 'date_p'
        },
        {
          key: 'clients_name',
          label: this.$t('Clients'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'clients_name'
        },
        {
          key: 'owner_name',
          label: this.$t('Proposal Owner'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'owner_name'
        },
        {
          key: 'owner_phone_number',
          label: this.$t('Proposal Owner phone'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'owner_phone_number'
        },
        {
          key: 'owner_email',
          label: this.$t('Proposal Owner email'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'owner_email'
        },
        {
          key: 'work_subject',
          label: this.$t('Work subject'),
          type: 'truncate',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'work_subject'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'is_closed', 'date_p', 'owner_name', 'owner_phone_number', 'owner_email', 'work_subject'],
      successfullyCreatedMessage: this.$t('Request created successfully. The email will be sent as soon as the request is processed.'),
      showUpdateOwnerDataModal: false,
      // Permissions
      deleteRolePerm: 'proposal_proposal_destroy',
      downloadPDFRolePerm: 'proposal_proposal_print_pdf',
      sendEmailRolePerm: 'proposal_proposal_send_emails_proposal_job',
      uploadSignedRolePerm: 'proposal_proposal_partial_update',
      downloadSignedRolePerm: 'proposal_proposal_download_signed',
      createQuotationRolePerm: ['miscellaneous_quotation_create', 'quotation_quotationrow_create', 'quotation_customerinvoiceaddress_create']
    }
  },
  computed: {
    ...mapGetters({
      apiUrl: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.GETTERS.apiUrl,
      selectAll: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.GETTERS.selectAll,
      selectedList: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('proposal_proposal_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.MUTATIONS.setList,
      setSelectedList:
        PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.MUTATIONS.setSelectedList,
      setSelectAll: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.ACTIONS,
      update: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.ACTIONS.update,
      partialUpdate: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.ACTIONS.partialUpdate,
      deleteProposal: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.ACTIONS.delete
    }),
    showModal (item, showModalName) {
      this.$set(this, 'proposal', item)
      this.$set(this, showModalName, true)
    },
    showConfirmModal (item) {
      this.$set(this, 'proposal', item)
      this.$set(this, 'showDeleteModal', true)
    },
    closeModal () {
      this.$set(this, 'proposal', null)
      this.$set(this, 'showSendEmailModal', false)
      this.$set(this, 'showUploadSignedModal', false)
      this.$set(this, 'showUpdateOwnerDataModal', false)
      this.$set(this, 'showDeleteModal', false)
    },
    sendProposalEmail (emails) {
      this.$set(this, 'apiCallJobUrl', `${this.apiUrl}/${this.proposal.id}/send-emails-proposal-job/`)
      this.doJob({ proposal_id: this.proposal.id, emails: emails })
    },
    afterExecuteJob () {
      this.closeModal()
    },
    downloadPDF (item) {
      const fileName = `${this.$t('Proposal')}_${item.owner_name.replace(/ /g, '')}_${item.date_p}.pdf`
      this.download({ id: item.id, action: 'print-pdf', simpleFileName: fileName })
    },
    uploadProposalSigned (file) {
      let formData = { id: this.proposal.id, ...this.proposal }
      if (formData.owner_contacts) {
        delete formData.owner_contacts
      }
      if (formData.supplier_contacts) {
        delete formData.supplier_contacts
      }
      if (formData.clients) {
        delete formData.clients
      }
      formData.signed_proposal = file
      formData = storeMixin.utils.parseFormMultipart(formData, ['signed_proposal'])
      this.partialUpdate(formData)
    },
    downloadProposalSigned (item) {
      this.download({ id: item.id, action: 'download-signed', simpleFileName: item.signed_filename })
    },
    createQuotationFromProposal (item) {
      if (item.owner_ssn || item.owner_vat_number) {
        this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_CREATE_QUOTATION, params: { proposalId: item.id } })
      } else {
        this.$set(this, 'proposal', item)
        this.$set(this, 'showUpdateOwnerDataModal', true)
      }
    },
    updateProposalData (evt) {
      const propId = this.proposal.id
      const formData = { id: this.proposal.id, ...this.proposal }

      if (formData.signed_proposal) {
        delete formData.signed_proposal
      }
      if (formData.supplier_contacts) {
        delete formData.supplier_contacts
      }
      formData.owner_ssn = evt.owner_ssn
      formData.owner_vat_number = evt.owner_vat_number
      this.update(formData).then(
        () => {
          this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_CREATE_QUOTATION, params: { proposalId: propId } })
        }
      )
    }
  }
}
</script>
