<template>
  <panel :title="$t('Yard data')" noButton="true">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.yard_name"
            :label-form="'Yard name' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="text"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.yard_name"
            :disabled="!formEditable"
            @change="$set(form, 'yard_name', $event)"
          />
        </b-form-row>
        <hr>
        <address-component
          :id="id"
          :detail="detailYardAddress"
          :form-editable-update="formEditable"
          :errors="addressErrors"
          :reset-address="resetFormAddress"
          @reset="$emit('reset')"
          @set-form="$emit('set-yard-proposal-address-form', $event)"
        />
        <hr>
        <b-form-row class="mt-3">
          <form-thux-horizontal-input
            :validator="$v.form.work_subject"
            :external-errors="errors['work_subject']"
            :label-form="'Work subject' | translate"
            class-form="col-12 mb-2 mt-2"
            label-form-class="col-half-width"
            type="text"
            :value="form.work_subject"
            :disabled="!formEditable"
            @change="$set(form, 'work_subject', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.organizations_number"
            :external-errors="errors['organizations_number']"
            :label-form="'N° Organizations' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="number"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.organizations_number"
            :disabled="!formEditable"
            @change="$set(form, 'organizations_number', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.freelance_number"
            :external-errors="errors['freelance_number']"
            :label-form="'N° Freelancers' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="number"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.freelance_number"
            :disabled="!formEditable"
            @change="$set(form, 'freelance_number', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.total_amount"
            :external-errors="errors['total_amount']"
            :label-form="'Yard total amount' | translate"
            class-form="col-lg-6 mb-2 mt-2"
            type="number"
            labelColsSm="3"
            labelColsLg="3"
            :value="form.total_amount"
            :disabled="!formEditable"
            @change="$set(form, 'total_amount', $event)"
          />
          <form-thux-horizontal-duration
            :validator="$v.form.planning_duration"
            :external-errors="errors['planning_duration']"
            :label-form="'Planning duration' | translate"
            class-form="col-lg-6 mb-2"
            labelColsSm="3"
            labelColsLg="3"
            type="text"
            :value="form.planning_duration"
            rows="3"
            max-rows="6"
            :disabled="!formEditable"
            @change="$set(form, 'planning_duration', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-duration
            :validator="$v.form.procurement_duration"
            :external-errors="errors['procurement_duration']"
            :label-form="'Procurement duration' | translate"
            class-form="col-lg-6 mb-2"
            labelColsSm="3"
            labelColsLg="3"
            type="text"
            :value="form.procurement_duration"
            rows="3"
            max-rows="6"
            :disabled="!formEditable"
            @change="$set(form, 'procurement_duration', $event)"
          />
          <form-thux-horizontal-duration
            :validator="$v.form.execution_duration"
            :external-errors="errors['execution_duration']"
            :label-form="'Execution duration' | translate"
            class-form="col-lg-6 mb-2"
            labelColsSm="3"
            labelColsLg="3"
            type="text"
            :value="form.execution_duration"
            rows="3"
            max-rows="6"
            :disabled="!formEditable"
            @change="$set(form, 'execution_duration', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-text-area
            :validator="$v.form.note"
            :external-errors="errors['Note']"
            :label-form="'Note' | translate"
            class-form="col-12 mb-2 mt-2"
            label-form-class="col-half-width"
            type="text"
            :value="form.note"
            rows="3"
            max-rows="8"
            :disabled="!formEditable"
            @change="$set(form, 'note', $event)"
          />
        </b-form-row>
      </b-form>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from '../proposal/store'
import { TYPES as YARD_PROPOSAL_TYPES } from '../yard-proposal-address/store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import AddressComponent from '../components/AddressComponent'

export default {
  name: 'ProposalEdit',
  components: { AddressComponent },
  mixins: [
    ThuxDetailPanelMixin
  ],
  props: {
    formEditableUpdate: {},
    resetForm: {},
    errors: {},
    owner: {}
  },
  watch: {
    detail () {
      this.init()
    },
    detailYardAddress () {
      this.$set(this.form, 'yard_name', this.detailYardAddress.yard_name)
    },
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    formEditableUpdate: {
      handler () {
        this.$set(this, 'formEditable', this.formEditableUpdate)
      },
      deep: true
    },
    resetForm () {
      this.setForm()
      this.$set(this, 'resetFormAddress', !this.resetFormAddress)
    }
  },
  data () {
    return {
      addressForm: {},
      resetFormAddress: false
    }
  },
  computed: {
    ...mapGetters({
      addressErrors: YARD_PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.DETAIL.GETTERS.errors,
      detailYardAddress: YARD_PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.DETAIL.GETTERS.detail,
      detail: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.GETTERS.detail
    })
  },
  methods: {
    init () {
      if (this.id) {
        this.makeFormReadonly()
        this.setForm()
      } else {
        this.makeFormEditable()
      }
    },
    setForm () {
      this.$set(this, 'form', {
        yard_name: this.detailYardAddress ? this.detailYardAddress.yard_name : '',
        work_subject: this.detail.work_subject,
        organizations_number: this.detail.organizations_number,
        freelance_number: this.detail.freelance_number,
        total_amount: this.detail.total_amount,
        planning_duration: this.detail.planning_duration,
        procurement_duration: this.detail.procurement_duration,
        execution_duration: this.detail.execution_duration,
        note: this.detail.note
      })
    }
  },
  validations: {
    form: {
      yard_name: {},
      work_subject: { required },
      organizations_number: {},
      freelance_number: {},
      total_amount: {},
      planning_duration: {},
      procurement_duration: {},
      execution_duration: {},
      note: {}
    }
  }
}
</script>
