<template>
  <div>
    <b-form-row v-for="(referent, index) in referents" :key="referent.id">
      <form-thux-horizontal-autocomplete
        :label-form="'Referent' | translate"
        class-form="col-lg-6 mb-2 mt-2"
        :disabled="!formEditableUpdate"
        :label-search="'name'"
        labelColsSm="3"
        labelColsLg="3"
        :value="referent.id ? { id: referent.id, name: referent.name } : null"
        :options="referentListOptions"
        :placeholder="$t('Search by last name')"
        :placeholder-field="referent.name"
        @search="onSearchReferent"
        @select="selectReferent($event, index)"
        @clear="clearReferent(index)"
      />
      <form-thux-horizontal-input
        v-if="referent"
        :label-form="''"
        class-form="col-lg-3 mb-2 mt-2"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="true"
        placeholder="phone"
        :value="referent.phone"
      >
        <template slot="placeholder">
          <i class="fas fa-phone"></i>
        </template>
      </form-thux-horizontal-input>
      <form-thux-horizontal-input
        v-if="referent"
        :label-form="''"
        class-form="col-lg-3 mb-2 mt-2"
        labelColsSm="0"
        labelColsLg="0"
        :disabled="true"
        placeholder="email"
        :value="referent.email"
      >
        <template slot="placeholder">
          <i class="fas fa-envelope"></i>
        </template>
      </form-thux-horizontal-input>
    </b-form-row>
  </div>
</template>

<script>
import FormThuxHorizontalAutocomplete from '../form-thux-horizontal-components/FormThuxHorizontalAutocomplete'
import FormThuxHorizontalInput from '../form-thux-horizontal-components/FormThuxHorizontalInput'
import ReferentMixin from './mixins/ReferentMixin'

export default {
  name: 'ReferentsManager',
  mixins: [ReferentMixin],
  components: {
    'form-thux-horizontal-autocomplete': FormThuxHorizontalAutocomplete,
    'form-thux-horizontal-input': FormThuxHorizontalInput
  },
  props: {
    formEditableUpdate: {},
    supplierContacts: {}
  },
  watch: {
    supplierContacts () {
      if (this.supplierContacts) {
        this.$set(this, 'referents', this.supplierContacts.map((referent) => {
          return {
            status: referent.status,
            id: referent.id,
            name: `${referent.first_name} ${referent.last_name}`,
            phone: referent.primary_phone,
            email: referent.primary_email
          }
        }))
        this.referents.push({})
      }
    }
  },
  data () {
    return {
      referents: [{}]
    }
  }
}
</script>
