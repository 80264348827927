<template>
  <span>
    <panel :title="$t('Proposal data')" noButton="true" v-has-perms="rolePerm">
      <div>
        <b-form>
          <b-form-row>
            <form-thux-horizontal-datetime
              :validator="$v.form.date_p"
              :external-errors="errors['date_p']"
              :label-form="'Date' | translate"
              labelColsSm="3"
              labelColsLg="3"
              :disabled="!formEditable"
              class-form="col-lg-6 mb-2 mt-2"
              type="date"
              :value="dateP"
              :placeholder="$t('Enter the date')"
              @change="dateChange($event, 'date_p')"
            />
            <form-thux-horizontal-select
              :validator="$v.form.analysis_type"
              :external-errors="errors['analysis_type']"
              :label-form="'Analysis type' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              labelColsSm="3"
              labelColsLg="3"
              :disabled="!formEditable"
              label-search="text"
              :value="form.analysis_type ? { value: form.analysis_type, text: form.analysis_type_name } : null"
              :placeholder="$t('Select an option')"
              :options="analysisTypeListOptions"
              @select="selectAnalysisType"
              @clear="clearAnalysisType"
            />
          </b-form-row>
          <b-form-row>
            <form-thux-horizontal-multiselect
              :validator="$v.form.clients"
              :external-errors="errors['clients']"
              :label-form="'Yard Clients' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              labelColsSm="3"
              labelColsLg="3"
              :disabled="!formEditable"
              :label-search="'name'"
              :value="form.clients"
              :options="clientsListOptions"
              :placeholder="
                $t('Select a {instance}', { instance: $t('clients') })
              "
              @search="onSearchClients"
              @remove="removeClients"
              @select="selectClients"
              @clear="$set(row, 'clients', null)"
            />
            <form-thux-horizontal-autocomplete
              :validator="$v.form.owner"
              :external-errors="errors['owner']"
              :label-form="'Holder' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              labelColsSm="3"
              labelColsLg="3"
              :disabled="!formEditable"
              :label-search="'name'"
              :value="form.owner ? { value: form.owner, name:  form.owner_typology_name ? `${form.owner_name} (${form.owner_typology_name})` :  form.owner_name } : null"
              :options="ownersOptions"
              :placeholder="form.owner ? form.owner_name : $t('Type a holder')"
              :placeholder-field="form.owner_name"
              @search="onSearchOwner"
              @select="selectOwner"
              @clear="clearOwner"
            />
          </b-form-row>
          <b-form-row>
            <form-thux-horizontal-input
              :validator="$v.form.owner_vat_number"
              :external-errors="errors['owner_vat_number']"
              :label-form="'VAT number' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              type="text"
              labelColsSm="3"
              labelColsLg="3"
              :value="form.owner_vat_number"
              :disabled="!formEditable"
              @change="$set(form, 'owner_vat_number', $event)"
            />
            <form-thux-horizontal-input
              :validator="$v.form.owner_ssn"
              :external-errors="errors['owner_ssn']"
              :label-form="'SSN' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              type="text"
              labelColsSm="3"
              labelColsLg="3"
              :value="form.owner_ssn"
              :disabled="!formEditable"
              @change="$set(form, 'owner_ssn', $event)"
            />
          </b-form-row>
          <b-form-row>
            <form-thux-horizontal-input
              :validator="$v.form.owner_phone_number"
              :external-errors="errors['owner_phone_number']"
              :label-form="'Phone' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              type="text"
              labelColsSm="3"
              labelColsLg="3"
              :value="form.owner_phone_number"
              :disabled="!formEditable"
              @change="$set(form, 'owner_phone_number', $event)"
            />
            <form-thux-horizontal-input
              :validator="$v.form.owner_email"
              :external-errors="errors['owner_email']"
              :label-form="'Email' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              type="email"
              labelColsSm="3"
              labelColsLg="3"
              :value="form.owner_email"
              :disabled="!formEditable"
              @change="$set(form, 'owner_email', $event)"
            />
          </b-form-row>
        </b-form>
      </div>
    </panel>
    <panel :title="$t('Pidueci referents')" noButton="true" v-has-perms="referentsRolePerm">
      <div>
        <b-form>
          <referents-manager :form-editable-update="formEditable" :supplier-contacts="detail.supplier_contacts" @update-referents="$set(form, 'supplier_contacts', $event ? $event : undefined)" />
        </b-form>
      </div>
    </panel>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from './store'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import OwnerMixin from './mixins/OwnerMixin'
import OwnerContactMixin from './mixins/OwnerContactMixin'
import AnalysisTypeMixin from './mixins/AnalysisTypeMixin'
import ReferentMixin from '@/components/owner-organization-referents/mixins/ReferentMixin'
import ReferentsManager from '@/components/owner-organization-referents/ReferentsManager'
import ClientsMixin from './mixins/ClientsMixin'

export default {
  name: 'ProposalEdit',
  mixins: [
    ThuxDetailPanelMixin,
    OwnerMixin,
    OwnerContactMixin,
    AnalysisTypeMixin,
    ReferentMixin,
    ClientsMixin
  ],
  components: {
    ReferentsManager
  },
  props: {
    formEditableUpdate: {},
    resetForm: {},
    errors: {}
  },
  watch: {
    detail () {
      this.init()
    },
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    formEditableUpdate () {
      this.$set(this, 'formEditable', this.formEditableUpdate)
    },
    resetForm () {
      this.setForm()
      this.$emit('reset')
    }
  },
  data () {
    return {
      dateP: undefined,
      rolePerm: 'proposal_proposal_retrieve',
      referentsRolePerm: 'proposal_proposal_retrieve' // TODO quale permesso qui?
    }
  },
  computed: {
    ...mapGetters({
      detail: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.GETTERS.detail
    })
  },
  methods: {
    init () {
      if (this.id) {
        this.makeFormReadonly()
        this.setForm()
      } else {
        this.makeFormEditable()
      }
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      delete form.work_subject
      delete form.organizations_number
      delete form.freelance_number
      delete form.total_amount
      delete form.planning_duration
      delete form.procurement_duration
      delete form.execution_duration
      delete form.note
      if (this.detail.get_analysis_type_display) {
        this.$set(form, 'analysis_type_name', this.detail.get_analysis_type_display)
      }
      if (this.detail.date_p) {
        this.$set(this, 'dateP', moment(new Date(this.detail.date_p)))
      }
      if (form.clients && form.clients.length > 0) {
        const filters = {}
        filters.filter__id__in = [form.clients]
        this.getClientsList(filters).then(() => {
          this.$set(form, 'clients', this.getClients(form))
          this.$set(this, 'form', form)
        })
      } else {
        this.$set(this, 'form', form)
      }
    },
    checkSsn () {
      if (this.form.owner_vat_number && this.form.owner_vat_number !== '') return false
      if (this.form.owner_ssn && this.form.owner_ssn !== '') return true
      return false
    },
    checkVat () {
      if (this.form.owner_ssn && this.form.owner_ssn !== '') return false
      if (this.form.owner_vat_number && this.form.owner_vat_number !== '') return true
      return false
    }
  },
  validations () {
    const form = {
      date_p: { required },
      clients: { },
      owner: { required },
      recipient: {},
      revision: {},
      revision_year: {},
      analysis_type: {},
      description: {},
      owner_vat_number: { },
      owner_ssn: { },
      owner_phone_number: {},
      owner_email: {}
    }
    return { form: form }
  }
}
</script>
