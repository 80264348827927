<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_ADDRESS_TYPES } from '../../../organization/organization/organization-address/store'
import { vue } from '../../../../main'

export default {
  name: 'OwnerAddressMixin',
  created () {
    this.setOrganizationAddressList({})
  },
  computed: {
    ...mapGetters({
      organizationAddressList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.GETTERS.list
    }),
    ownerAddressOptions () {
      if (this.organizationAddressList) {
        return this.prepareListForSelect(this.organizationAddressList.results, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapMutations({
      setOrganizationAddressList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getOrganizationAddressList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.ACTIONS.setFilters
    }),
    onSearchOwnerAddress (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status.-date_create'
        this.getOrganizationAddressList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setOrganizationAddressList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectOwnerAddress (item) {
      this.$set(this.form, 'owner_address', item.value)
      this.$set(this.form, 'owner_address_name', item.name)
    },
    clearOwnerAddress () {
      this.$set(this.form, 'owner_address', null)
      this.$set(this.form, 'owner_address_name', null)
    }
  }
}
</script>
