<template>
  <b-modal
    id="updateOwnerDataModal"
    ref="updateOwnerDataModal"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
    :scrollable="true"
    size="lg"
    @hidden="updateOwnerDataModalHidden"
  >
    <div slot="modal-title">
      {{ title }}
    </div>
    <slot name="body">
      <div style="font-size: 1.1rem">
        {{warningMessage}} {{ownerName}}
      </div>
      <div style="font-size: 1.1rem">
        {{opMessage}}
      </div>
      <b-form>
          <b-form-row>
            <form-thux-horizontal-input
              :validator="$v.form.owner_vat_number"
              :label-form="'VAT number' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              type="text"
              labelColsSm="4"
              labelColsLg="4"
              :value="form.owner_vat_number"
              @change="$set(form, 'owner_vat_number', $event)"
            />
            <form-thux-horizontal-input
              :validator="$v.form.owner_ssn"
              :label-form="'SSN' | translate"
              class-form="col-lg-6 mb-2 mt-2"
              type="text"
              labelColsSm="4"
              labelColsLg="4"
              :value="form.owner_ssn"
              @change="$set(form, 'owner_ssn', $event)"
            />
          </b-form-row>
      </b-form>
      <div class="note note-light-primary">
        <div class="note-content">
          {{noteMessage}}
        </div>
      </div>
    </slot>
    <div slot="modal-footer">
      <!--BUTTON CANCEL-->
      <b-btn class="mr-2" variant="default" @click="updateOwnerDataModalHidden">
        {{ 'Cancel' | translate }}
      </b-btn>
      <!--BUTTON SEND-->
      <b-btn variant="primary" :disabled="onSubmitDisabled" @click="onSubmit">
        {{ 'Save' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import FormThuxHorizontalInput from '@/components/form-thux-horizontal-components/FormThuxHorizontalInput'
import { TYPES as ORG_TYPES } from '../../organization/organization/store'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'UpdateOwnerDataModal',
  props: {
    title: { required: true },
    ownerName: { required: true },
    ownerId: { required: true }
  },
  components: {
    'form-thux-horizontal-input': FormThuxHorizontalInput
  },
  data () {
    return {
      form: {},
      warningMessage: this.$t('It is not possible to create a quote because neither the tax code nor the VAT number of the holder was indicated in the proposal'),
      opMessage: this.$t('Enter the tax code or VAT number of the holder'),
      noteMessage: this.$t('The insertion of the tax code / VAT number will not change the personal data of the holder, but only the single proposal.')
    }
  },
  mounted () {
    this.openUpdateOwnerDataModal()
  },
  created () {
    this.getOwnerDetail({ id: this.ownerId }).then(
      () => {
        this.$set(this.form, 'owner_ssn', this.ownerDetail.ssn)
        this.$set(this.form, 'owner_vat_number', this.ownerDetail.vat_number)
      }
    )
  },
  computed: {
    ...mapGetters({
      ownerDetail: ORG_TYPES.GENERIC.organization.organization.DETAIL.GETTERS.detail
    }),
    onSubmitDisabled () {
      if (!this.form.owner_ssn && !this.form.owner_vat_number) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      getOwnerDetail: ORG_TYPES.GENERIC.organization.organization.DETAIL.ACTIONS.retrieve
    }),
    // MODAL
    openUpdateOwnerDataModal () {
      this.$refs.updateOwnerDataModal.show()
    },
    updateOwnerDataModalHidden () {
      this.$emit('close')
      this.$refs.updateOwnerDataModal.hide()
    },
    onSubmit () {
      this.$emit('submit-and-update', this.form)
      this.updateOwnerDataModalHidden()
    }
  },
  validations: {
    form: {
      owner_vat_number: { },
      owner_ssn: { }
    }
  }
}
</script>
