<template>
  <panel :title="$t('Owner proposal address')" noButton="true">
    <div>
      <b-form class="mb-3" v-if="owner">
        <b-button :disabled="!formEditable" size="md" variant="primary" @click.prevent="openSelectAddressModal()">
          <i class="fas fa-map-pin mr-1"></i>
          {{ 'Select address' | translate }}
        </b-button>
      </b-form>
      <address-component
        :id="id"
        :detail="detailOwnerAddress"
        :errors="addressErrors"
        :form-editable-update="formEditable"
        :reset-address="resetFormAddress"
        :selectedAddress="selectedAddress"
        @reset="$emit('reset')"
        @set-form="$emit('set-owner-proposal-address-form', $event)"
      />
    </div>
    <select-address-modal v-if="showSelectAddressModal" :orgId="owner" v-on:close-select-address="closeSelectAddressModal"></select-address-modal>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from '../proposal/store'
import { TYPES as OWNER_PROPOSAL_TYPES } from '../owner-proposal-address/store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import AddressComponent from '../components/AddressComponent'
import SelectAddressModal from './SelectAddressModal'

export default {
  name: 'OwnerProposalAddressForm',
  components: {
    AddressComponent,
    SelectAddressModal
  },
  mixins: [
    ThuxDetailPanelMixin
  ],
  props: {
    formEditableUpdate: {},
    resetForm: {},
    errors: {},
    owner: {}
  },
  watch: {
    detail () {
      this.init()
    },
    form: {
      handler () {
        this.$emit('set-form', this.form)
      },
      deep: true
    },
    formEditableUpdate () {
      this.$set(this, 'formEditable', this.formEditableUpdate)
    },
    resetForm () {
      this.setForm()
      this.$set(this, 'resetFormAddress', !this.resetFormAddress)
    }
  },
  data () {
    return {
      addressForm: {},
      showSelectAddressModal: false,
      selectedAddress: {},
      resetFormAddress: false
    }
  },
  computed: {
    ...mapGetters({
      addressErrors: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.GETTERS.errors,
      detailOwnerAddress: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.GETTERS.detail,
      detail: PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.GETTERS.detail
    })
  },
  methods: {
    init () {
      if (this.id) {
        this.makeFormReadonly()
        this.setForm()
      } else {
        this.makeFormEditable()
      }
    },
    openSelectAddressModal () {
      this.showSelectAddressModal = true
    },
    closeSelectAddressModal (evtData) {
      if (evtData) {
        this.$set(this.form, 'owner_address', evtData.id)
        this.selectedAddress = evtData
      }
      this.showSelectAddressModal = false
    }
  }
}
</script>
