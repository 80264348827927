<script>
import Vue from 'vue'
import store from '../../store'
import { axiosThux } from '../../axiosThux'
import { vue } from '../../main'

export default {
  name: 'JobMixin',
  data () {
    return {
      job: {},
      pollingTimeout: undefined,
      cookieName: '',
      apiCallPollingUrl: '',
      apiCallJobUrl: '',
      classes: '',
      timeout: 3000,
      successfullyMessage: 'Job executed successfully',
      successfullyCreatedMessage: this.$t('Started job')
    }
  },
  destroyed () {
    if (this.job.status === -1 || this.job.status === 0) {
      Vue.cookie.set(this.cookieName, true)
    } else {
      Vue.cookie.remove(this.cookieName)
    }
    if (this.pollingTimeout) clearTimeout(this.pollingTimeout)
  },
  computed: {
    hasProfilePermission () {
      return true
    }
  },
  methods: {
    apiUrlParsed (url) {
      if (url.indexOf('{profile}') > -1) {
        url = url.replace('{profile}', store.getters['auth/profile'])
      }
      return url
    },
    executeJob (data = {}) {
      return axiosThux
        .post(this.apiUrlParsed(this.apiCallJobUrl), data)
        .then((response) => {
          this.$set(this, 'job', response.data)
          this.$store.dispatch(
            'notifySuccess',
            { text: this.successfullyCreatedMessage },
            { root: true }
          )
        })
        .catch((error) => {
          this.$set(this, 'job', {})
          this.$store.dispatch(
            'notifyError',
            { error: error.response.data },
            { root: true }
          )
          throw error
        })
    },
    checkStatusExecuteJob () {
      return axiosThux
        .get(this.apiUrlParsed(this.apiCallPollingUrl))
        .then((response) => {
          this.$set(this, 'job', response.data)
        })
        .catch((error) => {
          this.$set(this, 'job', {})
          this.$store.dispatch(
            'notifyError',
            { error: error.response.data },
            { root: true }
          )
          throw error
        })
    },
    afterExecuteJob () {
      Vue.cookie.set(this.cookieName, true)
      this.$set(this, 'pollingTimeout', setTimeout(this.doJobPolling, this.timeout))
    },
    doJob (data = {}) {
      return this.executeJob(data).then(() => {
        this.afterExecuteJob()
      })
    },
    doJobPolling () {
      if (!this.hasProfilePermission) return
      vue.$store.commit('componentLoading', false)
      this.checkStatusExecuteJob().then(() => {
        if (this.job.status === 1 && Vue.cookie.get(this.cookieName)) {
          Vue.cookie.remove(this.cookieName)
          vue.$store.commit('componentLoading', true)
          this.$store.dispatch(
            'notifySuccess',
            { text: this.$t(this.successfullyMessage) },
            { root: true }
          )
        } else if (this.job.status === -1 || this.job.status === 0) {
          vue.$store.commit('componentLoading', true)
          this.$set(this, 'pollingTimeout', setTimeout(this.doJobPolling, this.timeout))
        }
      })
    }
  }
}
</script>
