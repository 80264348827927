<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from './store'
import { ROUTES as PROPOSAL_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import ProposalTable from './ProposalTable'
import ProposalCommonFilters from './ProposalCommonFilters'
import ProposalEdit from './ProposalEdit.vue'

export default {
  name: 'proposalList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': ProposalTable,
    'common-filters': ProposalCommonFilters,
    'component-edit': ProposalEdit
  },
  data () {
    return {
      PROPOSAL_ROUTES,
      title: this.$t('Proposals'),
      rolePerm: ['proposal_proposal_list'],
      actionEnablePermission: ['proposal_proposal_enable'],
      actionDisablePermission: ['proposal_proposal_disable'],
      searchFields: [
        { key: 'filter__owner__name__icontains', placeholder: this.$t('Proposal Owner'), type: 'text', col: 4 },
        { key: 'filter__owner_contact__last_name__icontains', placeholder: this.$t('Proposal Contact last name'), type: 'text', col: 4 },
        { key: 'filter__owner_contact__first_name__icontains', placeholder: this.$t('Proposal Contact first name'), type: 'text', col: 4 },
        { key: 'filter__work_subject__icontains', placeholder: this.$t('Work subject'), type: 'text', col: 4 },
        { key: 'filter__date_p__gte', placeholder: this.$t('Date greater than equal'), type: 'date', col: 4 },
        { key: 'filter__date_p__lte', placeholder: this.$t('Date less than equal'), minDate: 'filter__date_p__gte', type: 'date', col: 4 }
      ],
      actionsCustom: [
        {
          value: 'close',
          text: this.$t('Set as closed')
        },
        {
          value: 'reopen',
          text: this.$t('Set as re-open')
        }

      ]
    }
  },
  computed: {
    ...mapGetters({
      ...PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.PROPOSAL_ROUTES.PROPOSAL_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('proposal_proposal_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.MUTATIONS.setSelectedList,
      setSelectAll: PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...PROPOSAL_TYPES.GENERIC.proposal.proposal.LIST.ACTIONS
    }),
    openDetailForm (id) {
      this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_DETAIL, params: { id: id } })
    },
    openEditForm () {
      this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_CREATE })
    },
    getComponentList () {
      return this.changeOrderBy('-date_p')
    },
    doCustomActions (actionType, idList, counter) {
      if (actionType === 'close') {
        idList.forEach((id) => {
          this.closeProposal(id).then(() => {
            if (counter === idList.length - 1) {
              this.getList()
              this.setSelectedList([])
            }
            counter += 1
          })
        })
      }
      if (actionType === 'reopen') {
        idList.forEach((id) => {
          this.reOpenProposal(id).then(() => {
            if (counter === idList.length - 1) {
              this.getList()
              this.setSelectedList([])
            }
            counter += 1
          })
        })
      }
    }
  }
}
</script>
