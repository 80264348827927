<template>
  <b-modal
    id="modalFileUpload"
    ref="modalFileUpload"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
    :scrollable="true"
    size="lg"
    @hidden="modalFileUploadHidden"
  >
    <div slot="modal-title">
      {{ title }}
    </div>
    <b-form>
      <form-thux-horizontal-file
        :validator="$v.form.file"
        :label-form="'File' | translate"
        class-form="col-lg-12 mb-2 mt-2"
        labelColsSm="4"
        labelColsLg="3"
        :value="form.file"
        :placeholder="$t('Choose a file or drop it here...')"
        :drop-placeholder="$t('Drop file here...')"
        @input="$set(form, 'file', $event)"
      />
    </b-form>
    <div slot="modal-footer">
      <!--BUTTON CANCEL-->
      <b-btn class="mr-2" variant="default" @click="modalFileUploadHidden">
        {{ 'Cancel' | translate }}
      </b-btn>
      <!--BUTTON SEND-->
      <b-btn variant="primary" :disabled="$v.form.$invalid" @click="onSubmit">
        {{ 'Save' | translate }}
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import FormThuxHorizontalFileThux from '@/components/form-thux-horizontal-components/FormThuxHorizontalFileThux'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'FileUploadModal',
  props: {
    title: { required: true }
  },
  components: {
    'form-thux-horizontal-file': FormThuxHorizontalFileThux
  },
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    this.openModalFileUpload()
  },
  methods: {
    // MODAL
    openModalFileUpload () {
      this.$refs.modalFileUpload.show()
    },
    modalFileUploadHidden () {
      this.$emit('close')
      this.$refs.modalFileUpload.hide()
    },
    onSubmit () {
      this.$emit('submit', this.form.file)
      this.modalFileUploadHidden()
    }
  },
  validations: {
    form: {
      file: { required }
    }
  }
}
</script>
