<template>
  <div class="panel-detail panel-fixed" v-has-perms="rolePerm">
    <div class="panel-header">
      <h1 class="page-header">
        {{ id && form.number ? `${$t('Proposal')} ${form.number}` : $t('Proposal') }}
      </h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span @click="goToList" class="underline-on-hover">
            {{ 'Proposals' | translate }}
          </span>
        </li>
        <li class="breadcrumb-item">
          <span>
            {{ 'Proposal' | translate }}
          </span>
        </li>
      </ol>
    </div>
    <proposal-edit-form
      :id="id"
      :form-editable-update="formEditable"
      :errors="errors"
      :reset-form="resetForm"
      @reset="reset"
      @set-form="setProposalDataForm"
    />
    <owner-proposal-address-form
      :id="id"
      :owner="form.owner"
      :form-editable-update="formEditable"
      :reset-form="resetFormOwner"
      @reset="reset"
      @set-form="setProposalDataForm"
      @set-owner-proposal-address-form="setOwnerProposalAddressForm"
    />
    <yard-proposal-address-form
      :id="id"
      :owner="form.owner"
      :form-editable-update="formEditable"
      :reset-form="resetFormYard"
      :errors="errors"
      @reset="reset"
      @set-form="setYardDataForm"
      @set-yard-proposal-address-form="setYardProposalAddressForm"
    >
    </yard-proposal-address-form>
    <div class="panel-actions">
      <b-button
        size="lg"
        class="mr-3"
        @click.prevent="formEditable ? cancel() : goToList()"
      >
        {{ formEditable ? 'Cancel' : 'Close' | translate }}
      </b-button>
      <b-button
          v-if="!this.detail || !this.detail.is_closed"
          size="lg"
          variant="primary"
          v-has-perms="editRolePerm"
          :disabled="formEditable ? $v.$invalid : false"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
      >
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>

    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { storeMixin } from '@/storeMixin'
import { ROUTES as PROPOSAL_ROUTES } from './router'
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as PROPOSAL_TYPES } from './store'
import { TYPES as OWNER_PROPOSAL_TYPES } from '../owner-proposal-address/store'
import { TYPES as YARD_PROPOSAL_TYPES } from '../yard-proposal-address/store'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'

import ProposalEditForm from './ProposalEditForm'
import OwnerProposalAddressForm from '../owner-proposal-address/OwnerProposalAddressForm'
import YardProposalAddressForm from '../yard-proposal-address/YardProposalAddressForm'

import { checkIfEqual } from '../../../const'
import ClientsMixinVue from './mixins/ClientsMixin.vue'

export default {
  name: 'ProposalDetail',
  mixins: [ThuxDetailPanelMixin, ClientsMixinVue],
  components: {
    ProposalEditForm,
    OwnerProposalAddressForm,
    YardProposalAddressForm
  },
  data () {
    return {
      resetForm: false,
      resetFormOwner: false,
      resetFormYard: false,

      // FORM
      form: {},
      formInitial: {},
      isFormChanged: false,

      // FORM YARD
      formYard: {},
      formYardInitial: {},
      isFormYardChanged: false,

      // OWNER PROPOSAL ADDRESS FORM
      ownerProposalAddressForm: {},
      ownerProposalAddressFormInitial: {},
      isOwnerProposalAddressFormChanged: false,

      // YARD PROPOSAL ADDRESS FORM
      yardProposalAddressForm: {},
      yardProposalAddressFormInitial: {},
      isYardProposalAddressFormChanged: false,

      // Permissions
      rolePerm: ['proposal_proposal_retrieve'],
      editRolePerm: ['proposal_proposal_update']
    }
  },
  created () {
    this.$set(this, 'formEditable', false)
    if (!this.id) {
      this.$set(this, 'formEditable', true)
    }
  },
  computed: {
    ...mapGetters({
      ...PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.GETTERS,
      detailOwnerAddress: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.GETTERS.detail,
      detailYardAddress: YARD_PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.DETAIL.GETTERS.detail
    })
  },
  methods: {
    ...mapActions({
      ...PROPOSAL_TYPES.GENERIC.proposal.proposal.DETAIL.ACTIONS,

      // OWNER PROPOSAL ADDRESS FORM
      retrieveOwnerAddress: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.ACTIONS.retrieve,
      createOwnerAddress: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.ACTIONS.create,
      updateOwnerAddress: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.ACTIONS.update,
      cleanOwnerAddressErrors: OWNER_PROPOSAL_TYPES.GENERIC.proposal.ownerproposaladdress.DETAIL.ACTIONS.cleanErrors,

      // YARD PROPOSAL ADDRESS FORM
      retrieveYardAddress: YARD_PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.DETAIL.ACTIONS.retrieve,
      createYardAddress: YARD_PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.DETAIL.ACTIONS.create,
      updateYardAddress: YARD_PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.DETAIL.ACTIONS.update,
      cleanYardAddressErrors: YARD_PROPOSAL_TYPES.GENERIC.proposal.yardproposaladdress.DETAIL.ACTIONS.cleanErrors
    }),
    init () {
      this.cleanErrors()
      this.initFields()
      if (this.id) {
        this.retrieve({ id: this.id }).then(
          () => {
            if (this.detail.owner_prop_address_id) {
              this.retrieveOwnerAddress({ id: this.detail.owner_prop_address_id }).then(() => {
                this.$set(this, 'ownerProposalAddressFormInitial', { ...this.detailOwnerAddress })
                this.$set(this, 'ownerProposalAddressForm', { ...this.detailOwnerAddress })
                this.$set(this, 'isOwnerProposalAddressFormChanged', false)
              })
            } else {
              this.$set(this, 'ownerProposalAddressFormInitial', { })
              this.$set(this, 'ownerProposalAddressForm', { })
              this.$set(this, 'isOwnerProposalAddressFormChanged', false)
            }
            if (this.detail.yard_address_id) {
              this.retrieveYardAddress({ id: this.detail.yard_address_id }).then(() => {
                this.$set(this, 'yardProposalAddressFormInitial', { ...this.detailYardAddress })
                this.$set(this, 'yardProposalAddressForm', { ...this.detailYardAddress })
                this.$set(this.formYardInitial, 'yard_name', this.detailYardAddress.yard_name)
                this.$set(this, 'isYardProposalAddressFormChanged', false)
                this.$set(this, 'isFormYardChanged', false)
              })
            } else {
              this.$set(this, 'yardProposalAddressFormInitial', { })
              this.$set(this, 'yardProposalAddressForm', { })
              this.$set(this.formYardInitial, 'yard_name', undefined)
              this.$set(this, 'isYardProposalAddressFormChanged', false)
              this.$set(this, 'isFormYardChanged', false)
            }
            this.makeFormReadonly()
            this.setForm()
          }
        )
      } else {
        this.makeFormEditable()
      }
    },
    setForm () {
      const form = Object.assign({}, cloneDeep(this.detail))
      this.$set(this, 'formYardInitial', {
        work_subject: this.detail.work_subject,
        organizations_number: this.detail.organizations_number,
        freelance_number: this.detail.freelance_number,
        total_amount: this.detail.total_amount,
        planning_duration: this.detail.planning_duration,
        procurement_duration: this.detail.procurement_duration,
        execution_duration: this.detail.execution_duration,
        note: this.detail.note
      })
      delete form.work_subject
      delete form.organizations_number
      delete form.freelance_number
      delete form.total_amount
      delete form.planning_duration
      delete form.procurement_duration
      delete form.execution_duration
      delete form.note
      this.$set(this, 'form', form)
      this.$set(this, 'formInitial', form)
      this.$set(this, 'isFormChanged', false)
    },
    setProposalDataForm (form) {
      this.$set(this, 'isFormChanged', false)
      const formData = Object.assign({}, { ...this.form, ...form })
      this.setClients(formData)
      this.$set(this, 'form', formData)
      if (!checkIfEqual(formData, this.formInitial)) {
        this.$set(this, 'isFormChanged', true)
      }
    },
    setYardDataForm (formYard) {
      this.$set(this, 'isFormYardChanged', false)
      const formData = { ...this.formYard, ...formYard }
      this.$set(this, 'formYard', formData)
      if (formYard.yard_name !== this.yardProposalAddressForm.yard_name) {
        this.$set(this.yardProposalAddressForm, 'yard_name', formYard.yard_name)
      }
      this.setYardProposalAddressForm(this.yardProposalAddressForm)
      const formInitial = cloneDeep(this.formYardInitial)
      const form = cloneDeep(formData)
      delete formInitial.yard_name
      delete form.yard_name
      if (!checkIfEqual(form, formInitial).length > 0) {
        this.$set(this, 'isFormYardChanged', true)
      }
    },
    setOwnerProposalAddressForm (ownerProposalAddressForm) {
      this.$set(this, 'isOwnerProposalAddressFormChanged', false)
      ownerProposalAddressForm = { ...this.ownerProposalAddressForm, ...ownerProposalAddressForm }
      this.$set(this, 'ownerProposalAddressForm', ownerProposalAddressForm)
      if (!checkIfEqual(ownerProposalAddressForm, this.ownerProposalAddressFormInitial)) {
        this.$set(this, 'isOwnerProposalAddressFormChanged', true)
      }
    },
    setYardProposalAddressForm (yardProposalAddressForm) {
      this.$set(this, 'isYardProposalAddressFormChanged', false)
      yardProposalAddressForm = { ...this.yardProposalAddressForm, ...yardProposalAddressForm }
      this.$set(this, 'yardProposalAddressForm', yardProposalAddressForm)
      if (!checkIfEqual(yardProposalAddressForm, this.yardProposalAddressFormInitial)) {
        this.$set(this, 'isYardProposalAddressFormChanged', true)
      }
    },
    checkIfFormIsEmpty (form) {
      if (form.address_scopes && form.address_scopes.length > 0) return false
      if (form.name && form.name !== '') return false
      if (form.number && form.number !== '') return false
      if (form.zip_code && form.zip_code !== '') return false
      if (form.locality && form.locality !== '') return false
      if (form.province && form.province !== '') return false
      if (form.country && form.country !== '') return false
      return true
    },
    getAddressValidator (form, validator) {
      if (!this.checkIfFormIsEmpty(form) || form.id) {
        validator.name = { required }
        validator.country = { required }
      } else {
        validator.name = {}
        validator.country = {}
      }
      return validator
    },
    cancel () {
      if (!this.id) {
        this.goToList()
      } else {
        this.makeFormReadonly()
        this.$set(this, 'resetForm', !this.resetForm)
        this.$set(this, 'resetFormOwner', !this.resetFormOwner)
        this.$set(this, 'resetFormYard', !this.resetFormYard)
      }
    },
    reset () {
      this.cleanErrors()
      this.cleanOwnerAddressErrors()
      this.cleanYardAddressErrors()
      this.$set(this, 'isFormChanged', false)
      this.$set(this, 'isFormYardChanged', false)
      this.$set(this, 'isOwnerProposalAddressFormChanged', false)
      this.$set(this, 'isYardProposalAddressFormChanged', false)
    },
    goToList () {
      this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_LIST })
    },
    createOrUpdateAddress (promises, addressId, isChanged, form, createFunctionName, updateFunctionName) {
      if (form && Object.keys(form).length > 0) {
        const formData = Object.assign({}, form)
        if (addressId) {
          if (isChanged) {
            promises.push(this[updateFunctionName](formData))
          }
        } else {
          formData.proposal = this.detail.id
          promises.push(this[createFunctionName](formData))
        }
      }
      return promises
    },
    createOrUpdateAddressForms (promises) {
      // create or update yard address
      if (this.yardProposalAddressForm.name && this.yardProposalAddressForm.country) {
        promises = this.createOrUpdateAddress(
          promises,
          this.detail.yard_address_id,
          this.isYardProposalAddressFormChanged,
          this.yardProposalAddressForm,
          'createYardAddress',
          'updateYardAddress'
        )
      }

      // create or update owner address
      if (this.ownerProposalAddressForm.name && this.ownerProposalAddressForm.country) {
        this.$set(this.ownerProposalAddressForm, 'owner_name', this.form.owner_name)
        promises = this.createOrUpdateAddress(
          promises,
          this.detail.owner_prop_address_id,
          this.isOwnerProposalAddressFormChanged,
          this.ownerProposalAddressForm,
          'createOwnerAddress',
          'updateOwnerAddress'
        )
      }
      return promises
    },
    setFirstRevisionYear (formData) {
      if (formData.date_p) {
        return new Date(formData.date_p).getFullYear()
      }
      return new Date().getFullYear()
    },
    onSubmit () {
      if (!this.id) {
        const formData = Object.assign({}, this.form, this.formYard)
        if (formData.supplier_contacts) {
          formData.supplier_contacts = formData.supplier_contacts.map((contact) => {
            if (contact.id) {
              return contact.id
            }
            return contact
          })
        }
        if (!formData.revision_year) {
          formData.revision_year = this.setFirstRevisionYear(formData)
        }
        this.create(formData).then(() => {
          const promises = this.createOrUpdateAddressForms([])
          Promise.all(promises).then(() => {
            this.cleanErrors()
            this.cleanOwnerAddressErrors()
            this.cleanYardAddressErrors()
            this.$router.push({ name: PROPOSAL_ROUTES.PROPOSAL_LIST })
          })
        })
      } else {
        let promises = []
        if (this.isFormChanged || this.isFormYardChanged) {
          const formData = Object.assign({}, this.form, this.formYard)
          if (formData.supplier_contacts) {
            formData.supplier_contacts = formData.supplier_contacts.map((contact) => {
              if (contact.id) {
                return contact.id
              }
              return contact
            })
          }
          promises.push(this.update(storeMixin.utils.parseFormMultipart(formData, ['signed_proposal'])))
        }
        promises = this.createOrUpdateAddressForms(promises)
        Promise.all(promises).then(() => {
          this.makeFormReadonly()
          this.reset()
          this.$set(this, 'formInitial', { ...this.form })
          this.$set(this, 'formYardInitial', { ...this.formYard })
          this.$set(this, 'ownerProposalAddressFormInitial', { ...this.ownerProposalAddressForm })
          this.$set(this, 'yardProposalAddressFormInitial', { ...this.yardProposalAddressForm })
        })
      }
    }
  },
  validations () {
    const validator = {
      ownerProposalAddressForm: {
        address_scopes: {},
        name: {},
        number: { maxLength: maxLength(20) },
        zip_code: { maxLength: maxLength(20) },
        locality: {},
        province: { maxLength: maxLength(2) },
        country: {}
      },
      yardProposalAddressForm: {
        yard_name: {},
        address_scopes: {},
        name: {},
        number: { maxLength: maxLength(20) },
        zip_code: { maxLength: maxLength(20) },
        locality: {},
        province: { maxLength: maxLength(2) },
        country: {}
      },
      form: {
        date_p: { required },
        owner: { required },
        recipient: {},
        owner_address: {},
        number: {},
        description: {},
        analysis_type: {},
        owner_name: {},
        owner_ssn: {},
        owner_vat_number: {},
        owner_email: {},
        owner_phone_number: {}
      },
      // yard
      formYard: {
        yard_name: {},
        work_subject: { required },
        organizations_number: {},
        freelance_number: {},
        total_amount: {},
        planning_duration: {},
        procurement_duration: {},
        execution_duration: {},
        note: {}
      }
    }
    validator.ownerProposalAddressForm = this.getAddressValidator(this.ownerProposalAddressForm, validator.ownerProposalAddressForm)
    validator.yardProposalAddressForm = this.getAddressValidator(this.yardProposalAddressForm, validator.yardProposalAddressForm)
    return validator
  }
}
</script>
