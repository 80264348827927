<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as ORG_ADDRESS_TYPES } from '../store'

export default {
  name: 'CountryMixin',
  created () {
    if (!this.countryList || (this.countryList && this.countryList.length === 0)) {
      this.getCountrylist()
    }
  },
  computed: {
    ...mapGetters({
      countryList: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.GETTERS.countryList
    }),
    countryListOptions () {
      const options = []
      if (this.countryList && this.countryList.length > 0) {
        this.countryList.forEach((country) => {
          const option = {
            value: country.iso2_code,
            name: country.printable_name,
            status: country.status
          }
          if (country.status === 0) {
            option.$isDisabled = true
          }
          options.push(option)
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getCountrylist: ORG_ADDRESS_TYPES.GENERIC.organization.organizationaddress.LIST.ACTIONS.getCountrylist
    }),
    selectCountry (item) {
      this.$set(this.form, 'country', item.value)
      this.$set(this.form, 'country_name', item.name)
    },
    clearCountry () {
      this.$set(this.form, 'country', null)
      this.$set(this.form, 'country_name', null)
    }
  }
}
</script>
